.home {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #4694fc;
    z-index: 100;

    img {
        width: 20%;
        margin-top: 150px;
        animation: title 2s ease-in-out infinite;
    }

    h1  {
        font-weight: 500;
        color: white;
        animation: ecommerce 1.5s ease-in-out infinite;
    }

    @keyframes title {
        from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
    }

    @keyframes ecommerce {
        0%{
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100%{
            opacity: 0.2;
        }
    }

    span {
        color: red;
    }
}

@media (max-width: 700px){
    .home {
        img {
            width: 50%;
        }
    }
}