.categories {
    padding-top: 50px;
    padding-left: 150px;

    .wrapper {
        padding: 50px;
        color: #4694fc;

        .message {
            color: #ff5555;
        }

        .loading-list {
            background-color: white;
            margin-bottom: 15px;
            height: 50px;
            animation: loading-list 2s infinite;
        }

        @keyframes loading-list {
            0%{
                opacity: 0.2;
            }
            50%{
                opacity: 0.6;
            }
            100%{
                opacity: 0.2;
            }
        }

        .new {
            height: 50px;
            width: 50px;
            border-radius: 25px;
            position: absolute;
            top: 70px;
            right: 50px;
            z-index: 5;
            background-color: #4694fc;
            box-shadow: 0px 2px 10px lightgray;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            animation: icon 0.7s ease-in-out;
            transition: 0.5s;
        }

        .new:hover {
            transform: scale(1.2);
            box-shadow: 0 10px 20px lightgray;
        }


        @keyframes icon {
            0%{
                transform: scale(0.2);
           
            }
            50%{
                transform:  scale(1.5);
            }
            100%{
                transform: scale(1);
            }
        }

        i {
            color: white;
        }

        .comfirm-delete {
            position: fixed;
            color: white;
            width: 100%;
            height: 100%;
            z-index: 300;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.5);


            div {
                margin: 80px auto;
                opacity: 1;
                width: 200px;
                background-color: #4694fc;
                padding: 20px;
                border-radius: 10px;
                text-align: center;
                animation: comfirm-delete 1s ease-in-out;

                h3 {
                    margin: 0;
                    font-weight: 500;
                }

                button {
                    padding: 2px 20px;
                    border-radius: 2px;
                    border: 1px solid white;
                    margin-right: 10px;
                    margin-left: 10px;
                    margin-top: 40px;
                    background-color: #4694fc;
                    color: white;
                    font-size: 15px;
                    cursor: pointer;
                }

                button:hover {
                    background: white;
                    color: #4694fc;
                }
                
            }

            @keyframes comfirm-delete {
                0%{
                    margin-top: -100px;
                    opacity: 0;
                }
                50%{
                    margin-top: 110px;
                }
                100%{
                    opacity: 1;
                    margin-top: 80px;
                }
            }
        }

        .category {
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 2px solid #b5d3fa;
            padding: 3px;
            color: #505050;
            align-items: center;


            .actions {
                display: flex;
            }

            span {
                margin-left: 3px;
                font-size: 20px;
                font-weight: 500;
            }

            .add {
                height: 30px;
                width: 30px;
                border-radius: 20px;
                background-color: #4694fc;
                margin-left: 7px;
                box-shadow: 0px 2px 10px lightgray;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                cursor: pointer;
            }

            .update {
                height: 30px;
                width: 30px;
                border-radius: 20px;
                background-color: #4DB649;
                margin-left: 7px;
                box-shadow: 0px 2px 10px lightgray;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                cursor: pointer;
            }

            .delete {
                height: 30px;
                width: 30px;
                border-radius: 20px;
                background-color: #ff5555;
                box-shadow: 0px 2px 10px lightgray;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                cursor: pointer;
            }

        }

        .add-subcategory {
            margin-top: 10px;
            background-color: white;
            padding: 5px;
            box-shadow: 0 3px 10px lightgray;
            animation: add 1s ease-in-out;
            border-radius: 3px;
            width: 98%;
            margin-bottom: 10px;
        
            @keyframes add {
                0%{
                    opacity: 0;
                    width: 0%;
                }
                50%{
                    width: 105%;
                }
                100%{
                    width: 99%;
                    opacity: 1;
                }
            }
        
            form {
                display: flex;
                justify-content: space-between;
            }
            
            input {
                font-size: 14px;
                padding: 5px;
                border: 1px solid #87B7FB;
                background-color: #f5f5f5;
                border-radius: 3px;
                width: 92%;
                margin-right: 10px;
            }
        
            button {
                background-color:  #4694fc;
                border: none;
                color: white;
                border-radius: 3px;
                padding: 0 20px;
                cursor: pointer;
            }
        }
        

        .list-sub {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 10px;
        }

    }
}

// responsive mobile
@media (max-width: 700px){
    .categories {
        padding-left: 0px;
        padding-bottom: 60px;
        
        .wrapper {
            padding: 30px 10px;

            .new {
                height: 40px;
                width: 40px;
                top: 65px;
                right: 10px;
            }

            .list-sub {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }

            img {
                width: 100%;
            }

            .add-subcategory {
                form {
                    display: flex;
                    flex-direction: column;
                    input {
                        margin-bottom: 5px;
                        width: auto;
                    }

                    button {
                        padding: 5px 0;
                    }
                }
            }
        }

    }
}
