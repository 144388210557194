body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F8F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'fontello';
    src: url('./assets/font/fontello.eot?77699535');
    src: url('./assets/font/fontello.eot?77699535#iefix') format('embedded-opentype'),
        url('./assets/font/fontello.woff?77699535') format('woff'),
        url('./assets/font/fontello.ttf?77699535') format('truetype'),
        url('./assets/font/fontello.svg?77699535#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .demo-icon
  {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
  
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
  
    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }