body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F8F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
    font-family: 'fontello';
    src: url(/static/media/fontello.6a9ae17f.eot);
    src: url(/static/media/fontello.6a9ae17f.eot#iefix) format('embedded-opentype'),
        url(/static/media/fontello.e36f163c.woff) format('woff'),
        url(/static/media/fontello.993ba0f4.ttf) format('truetype'),
        url(/static/media/fontello.9b125aa6.svg#fontello) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .demo-icon
  {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
  
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
  
    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
.home {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #4694fc;
  z-index: 100; }
  .home img {
    width: 20%;
    margin-top: 150px;
    -webkit-animation: title 2s ease-in-out infinite;
            animation: title 2s ease-in-out infinite; }
  .home h1 {
    font-weight: 500;
    color: white;
    -webkit-animation: ecommerce 1.5s ease-in-out infinite;
            animation: ecommerce 1.5s ease-in-out infinite; }

@-webkit-keyframes title {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes title {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes ecommerce {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

@keyframes ecommerce {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }
  .home span {
    color: red; }

@media (max-width: 700px) {
  .home img {
    width: 50%; } }

.login .wrapper {
  background-color: white;
  width: 310px;
  height: 385px;
  border-radius: 15px;
  box-shadow: 0px 2px 0px lightgray;
  position: absolute;
  right: 15%;
  top: 110px;
  z-index: 50;
  -webkit-animation: form 2s ease-in-out;
          animation: form 2s ease-in-out; }
  .login .wrapper img {
    width: 100%;
    position: absolute; }
  .login .wrapper .error {
    color: #c83737;
    position: absolute;
    top: 13px; }
  .login .wrapper form {
    margin-top: -116px;
    margin-left: 25px;
    margin-right: 25px;
    position: relative; }

@-webkit-keyframes form {
  0% {
    top: 0;
    opacity: 0; }
  100% {
    top: 110px;
    opacity: 1; } }

@keyframes form {
  0% {
    top: 0;
    opacity: 0; }
  100% {
    top: 110px;
    opacity: 1; } }
  .login .wrapper h2 {
    margin-top: 230px;
    color: #5daafd;
    font-weight: 400;
    margin-bottom: 20px; }
  .login .wrapper label {
    color: #5daafd;
    position: absolute; }
  .login .wrapper input {
    width: 91%;
    padding: 5px 10px;
    margin-top: 7px;
    margin-bottom: 10px;
    border: 1px solid #bed7fc;
    font-size: 16px;
    background-color: #eaf4ff;
    border-radius: 3px;
    outline: none;
    box-shadow: 0 3px 3px lightgray;
    transition: 0.5s;
    position: absolute; }
  .login .wrapper input:focus {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background-color: white; }
  .login .wrapper #username {
    top: 70px; }
  .login .wrapper #password {
    top: 140px; }
  .login .wrapper button {
    width: 260px;
    font-size: 16px;
    background-image: linear-gradient(#5daafd, #4bcffa);
    border: none;
    color: white;
    padding: 7px;
    border-radius: 3px;
    box-shadow: 0px 3px 20px lightgrey;
    transition: 0.5s;
    position: absolute;
    top: 200px;
    -webkit-animation: button 1.5s ease-in;
            animation: button 1.5s ease-in; }

@-webkit-keyframes button {
  0% {
    top: 0;
    opacity: 0; }
  50% {
    top: 220px; }
  100% {
    opacity: 1;
    top: 200px; } }

@keyframes button {
  0% {
    top: 0;
    opacity: 0; }
  50% {
    top: 220px; }
  100% {
    opacity: 1;
    top: 200px; } }
  .login .wrapper button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer; }

.login .left .background {
  position: fixed;
  width: auto;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1; }

.login .left .person {
  z-index: 5;
  position: fixed;
  height: 40%;
  top: 45%;
  left: 15%; }

.login .left .screen1 {
  top: 15%;
  left: 23%;
  z-index: 2;
  height: 30%;
  position: fixed;
  -webkit-animation: screen1 2s ease-in-out infinite;
          animation: screen1 2s ease-in-out infinite; }

@-webkit-keyframes screen1 {
  0% {
    top: 15%; }
  50% {
    top: 10%; }
  100% {
    top: 15%; } }

@keyframes screen1 {
  0% {
    top: 15%; }
  50% {
    top: 10%; }
  100% {
    top: 15%; } }

.login .left .screen2 {
  top: 20%;
  left: 13%;
  z-index: 2;
  height: 15%;
  position: fixed;
  -webkit-animation: screen2 1.5s ease-in-out infinite;
          animation: screen2 1.5s ease-in-out infinite; }

@-webkit-keyframes screen2 {
  0% {
    top: 25%; }
  50% {
    top: 30%; }
  100% {
    top: 25%; } }

@keyframes screen2 {
  0% {
    top: 25%; }
  50% {
    top: 30%; }
  100% {
    top: 25%; } }

.login .left .screen3 {
  top: 40%;
  left: 25%;
  z-index: 2;
  height: 25%;
  position: fixed;
  -webkit-animation: screen3 2s ease-in-out infinite;
          animation: screen3 2s ease-in-out infinite; }

@-webkit-keyframes screen3 {
  0% {
    top: 43%; }
  50% {
    top: 48%; }
  100% {
    top: 43%; } }

@keyframes screen3 {
  0% {
    top: 43%; }
  50% {
    top: 48%; }
  100% {
    top: 43%; } }

.login .left .icons {
  position: fixed;
  z-index: 2;
  height: 70%;
  top: 8%;
  left: 6%;
  -webkit-animation: icons 2s ease-in-out;
          animation: icons 2s ease-in-out; }

@-webkit-keyframes icons {
  0% {
    top: -50%;
    opacity: 0; }
  75% {
    top: 15%; }
  100% {
    top: 8%;
    opacity: 1; } }

@keyframes icons {
  0% {
    top: -50%;
    opacity: 0; }
  75% {
    top: 15%; }
  100% {
    top: 8%;
    opacity: 1; } }

@media (max-width: 700px) {
  .login {
    display: flex;
    justify-content: center; }
    .login .wrapper {
      position: relative;
      left: auto;
      right: auto; } }

.loading {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500; }
  .loading img {
    width: 20%;
    margin-top: 100px;
    -webkit-animation: title 2s ease-in-out infinite;
            animation: title 2s ease-in-out infinite; }
  .loading h1 {
    font-weight: 400;
    color: #4694fc;
    -webkit-animation: ecommerce 1.5s ease-in-out infinite;
            animation: ecommerce 1.5s ease-in-out infinite; }

@-webkit-keyframes title {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes title {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes ecommerce {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

@keyframes ecommerce {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.2; } }

.dashboard {
  padding-top: 50px;
  padding-left: 150px; }
  .dashboard .wrapper {
    padding: 50px; }
    .dashboard .wrapper span {
      color: #4694fc; }
    .dashboard .wrapper .total {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px; }
      .dashboard .wrapper .total i {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #4694fc;
        font-size: 90px; }
      .dashboard .wrapper .total .card {
        cursor: pointer;
        background-color: white;
        width: 30%;
        border-radius: 17px;
        box-shadow: 0px 3px 5px lightgrey;
        display: flex;
        -webkit-animation: card 1s ease-in-out;
                animation: card 1s ease-in-out;
        transition: 0.5s; }
        .dashboard .wrapper .total .card div {
          width: 50%;
          text-align: center; }
          .dashboard .wrapper .total .card div p {
            margin-top: 10px;
            color: gray;
            margin-bottom: 10px;
            font-size: 15px; }
          .dashboard .wrapper .total .card div h1 {
            margin-top: 0px;
            color: #4694fc;
            font-weight: 400;
            font-size: 50px;
            margin-bottom: 10px; }
      .dashboard .wrapper .total .card:hover {
        box-shadow: 0 10px 50px lightgray;
        width: 35%; }

@-webkit-keyframes card {
  0% {
    opacity: 0;
    margin-top: -100px; }
  100% {
    opacity: 1;
    margin-top: 0; } }

@keyframes card {
  0% {
    opacity: 0;
    margin-top: -100px; }
  100% {
    opacity: 1;
    margin-top: 0; } }
    .dashboard .wrapper button {
      background-color: #4694fc;
      font-size: 14px;
      padding: 3px 20px;
      border: none;
      color: white;
      cursor: pointer;
      border-radius: 2px; }
    .dashboard .wrapper .form-bank {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px; }
      .dashboard .wrapper .form-bank input {
        margin-bottom: 10px;
        font-size: 14px;
        padding: 5px 5px;
        border: 1px solid lightblue; }
    .dashboard .wrapper .load div {
      width: 100%;
      height: 50px;
      background-color: lightgray;
      margin-bottom: 10px;
      -webkit-animation: load 2s infinite;
              animation: load 2s infinite;
      border-radius: 20px; }

@-webkit-keyframes laod {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.2; } }

@keyframes laod {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.2; } }
    .dashboard .wrapper .bank {
      background-color: white;
      margin-top: 10px;
      padding: 10px;
      box-shadow: 0px 3px 5px lightgrey;
      border-radius: 3px;
      position: relative; }
      .dashboard .wrapper .bank div {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: 5px;
        grid-gap: 10px;
        color: #4694fc; }
        .dashboard .wrapper .bank div span {
          color: #505050; }
      .dashboard .wrapper .bank .action {
        position: absolute;
        right: 0px; }
        .dashboard .wrapper .bank .action span {
          top: -20px;
          right: 13px;
          color: gray;
          cursor: pointer;
          font-weight: 600;
          font-size: 30px;
          position: absolute; }
        .dashboard .wrapper .bank .action button {
          position: absolute;
          top: 20px;
          right: 10px;
          -webkit-animation: button 1s ease-in-out;
                  animation: button 1s ease-in-out; }

@-webkit-keyframes button {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes button {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@media (max-width: 700px) {
  .dashboard {
    padding-bottom: 60px;
    padding-left: 0px;
    padding-top: 60px; }
    .dashboard .wrapper {
      padding: 10px; }
      .dashboard .wrapper .total {
        flex-wrap: wrap; }
        .dashboard .wrapper .total .card {
          width: 100%;
          margin-bottom: 20px; }
        .dashboard .wrapper .total .card:hover {
          box-shadow: 0 10px 50px lightgray;
          width: 100%; } }

.header {
  top: 0;
  position: fixed;
  width: 100%;
  background-color: #4694fc;
  color: white;
  height: 60px;
  display: flex;
  z-index: 10;
  left: 0;
  right: 0;
  box-shadow: 0px 3px 5px lightgray;
  justify-content: space-between;
  align-items: center; }
  .header .title {
    margin-left: 12px;
    font-size: 22px;
    font-weight: 500; }
  .header .admin {
    margin-right: 18px; }
    .header .admin span {
      cursor: pointer; }
    .header .admin .dropdown {
      width: 150px;
      height: auto;
      position: absolute;
      background-color: white;
      color: gray;
      padding: 15px;
      margin-top: 10px;
      right: 25px;
      border-radius: 20px;
      box-shadow: 0px 3px 3px lightgray;
      display: flex;
      flex-direction: column;
      -webkit-animation: admin 1s ease-in-out;
              animation: admin 1s ease-in-out;
      text-align: center; }
      .header .admin .dropdown img {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        margin-left: 11px;
        background-color: lightgray; }
      .header .admin .dropdown .name {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 500;
        color: #4694fc; }
      .header .admin .dropdown .email {
        margin-top: 5px;
        font-size: 13px; }
      .header .admin .dropdown .logout {
        margin-top: 15px;
        text-align: center;
        background-color: #F6535A;
        padding: 5px 5px;
        color: white;
        border-radius: 20px;
        box-shadow: 0px 5px 10px lightgray;
        transition: 0.5s; }
      .header .admin .dropdown .logout:hover {
        box-shadow: 0px 10px 10px lightgray; }

@-webkit-keyframes admin {
  0% {
    top: -500px; }
  50% {
    top: 80px; }
  100% {
    top: 40px; } }

@keyframes admin {
  0% {
    top: -500px; }
  50% {
    top: 80px; }
  100% {
    top: 40px; } }
    .header .admin .hide {
      display: none; }

.navbar {
  width: 150px;
  height: 100%;
  color: red;
  background-color: white;
  position: fixed;
  top: 60px;
  left: 0px;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  box-shadow: 1px 0 5px lightgrey;
  transition: 1s;
  z-index: 200; }
  .navbar .link {
    margin-bottom: 55px;
    color: #4694fc;
    text-decoration: none;
    transition: 0.5s; }
  .navbar .link:hover {
    color: white;
    width: 100%;
    background-image: linear-gradient(to left, #5E80FC, #80befc);
    box-shadow: 0 10px 20px lightgray;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0 0px 0px 0; }
  .navbar .active {
    color: white;
    width: 100%;
    background-image: linear-gradient(to left, #5E80FC, #80befc);
    box-shadow: 0 10px 20px lightgray;
    padding: 10px 0px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 0 0px 0px 0;
    -webkit-animation: active 0.2s linear;
            animation: active 0.2s linear; }

@-webkit-keyframes active {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  50% {
    -webkit-transform: scaleX(0.5);
            transform: scaleX(0.5); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }

@keyframes active {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  50% {
    -webkit-transform: scaleX(0.5);
            transform: scaleX(0.5); }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); } }
  .navbar i {
    margin-right: 10px;
    margin-left: 15px; }

@media (max-width: 700px) {
  .navbar {
    width: 100%;
    flex-direction: row;
    top: auto;
    left: 0;
    bottom: 0;
    height: 50px;
    justify-content: space-around;
    padding-top: 0px; }
    .navbar span {
      display: none; }
    .navbar .link {
      padding-top: 13px;
      width: auto;
      margin-bottom: 0px;
      padding-left: 0;
      width: auto;
      font-size: 20px; }
    .navbar .link:hover {
      color: white;
      width: 60px;
      padding: 0px 0px;
      height: 60px;
      background-image: linear-gradient(#5E80FC, #5E80FC);
      box-shadow: 0px 5px 5px lightgrey;
      font-size: 27px;
      border-radius: 50%;
      margin-top: -15px;
      display: flex;
      align-items: center;
      align-content: center; }
    .navbar .active {
      border-radius: 50%;
      background-color: #4694fc;
      width: 60px;
      margin-top: -15px;
      display: flex;
      align-items: center;
      align-content: center;
      height: 60px;
      padding: 0px 0px;
      background-image: linear-gradient(#5E80FC, #5E80FC);
      box-shadow: 0px 5px 5px lightgrey;
      color: white;
      font-size: 27px;
      -webkit-animation: active 0.5s ease-in-out;
              animation: active 0.5s ease-in-out; }
  @-webkit-keyframes active {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
  @keyframes active {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1); }
    50% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1); } }
    .navbar i {
      margin-left: 15px;
      margin-right: 15px; } }

.orders {
  padding-top: 50px;
  padding-left: 150px; }
  .orders .tab {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 15px;
    margin-bottom: 20px;
    -webkit-animation: tab 1s ease-in-out;
            animation: tab 1s ease-in-out; }
    .orders .tab div {
      background-color: white;
      padding: 5px 0;
      text-align: center;
      box-shadow: 0 2px 3px lightgrey;
      cursor: pointer;
      border-radius: 20px;
      transition: 0.5s; }
    .orders .tab div:hover {
      background-color: #4694fc;
      color: white;
      box-shadow: 0 5px 10px lightgrey; }
    .orders .tab .active {
      background-color: #4694fc;
      color: white; }

@-webkit-keyframes tab {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes tab {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .orders .wrapper {
    padding: 50px;
    color: #4694fc; }

@media (max-width: 700px) {
  .orders {
    padding-top: 60px;
    padding-left: 0px;
    padding-bottom: 60px; }
    .orders .tab {
      grid-template-columns: 1fr 1fr; }
    .orders .wrapper {
      padding: 10px; } }

.new-order {
  position: relative; }
  .new-order .load div {
    width: 100%;
    height: 50px;
    background-color: lightgray;
    margin-bottom: 10px;
    -webkit-animation: load 2s infinite;
            animation: load 2s infinite; }

@-webkit-keyframes laod {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.2; } }

@keyframes laod {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.2; } }
  .new-order .confirm {
    position: absolute;
    background-color: #4694FC;
    color: white;
    padding: 10px 20px;
    right: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 2px;
    font-weight: 500; }
  .new-order .confirm:hover {
    box-shadow: 0 10px 20px lightgray; }
  .new-order .confirm-process {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 250; }
    .new-order .confirm-process div {
      background-color: white;
      width: 300px;
      padding: 20px;
      margin: 100px auto;
      border-radius: 10px; }
      .new-order .confirm-process div label {
        font-size: 18px;
        font-weight: 500; }
      .new-order .confirm-process div input {
        border: 1px solid #4694FC;
        border-radius: 3px;
        width: 95%;
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 18px;
        padding: 3px 5px; }
      .new-order .confirm-process div button {
        margin-left: 35px;
        background-color: #4694FC;
        border: none;
        font-size: 16px;
        padding: 5px 20px;
        border-radius: 25px;
        color: white;
        cursor: pointer; }
      .new-order .confirm-process div button:hover {
        box-shadow: 0 10px 10px lightgrey; }
  .new-order .order {
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;
    padding: 10px;
    box-shadow: 0 3px 5px lightgray;
    -webkit-animation: order 1s ease-in-out;
            animation: order 1s ease-in-out; }
    .new-order .order span {
      color: #505050;
      font-size: 18px;
      font-weight: 500; }
  .new-order .hide {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; }
    .new-order .hide span {
      font-size: 16px; }

@-webkit-keyframes order {
  0% {
    margin-top: -50px;
    opacity: 0; }
  100% {
    margin-top: 0;
    opacity: 1; } }

@keyframes order {
  0% {
    margin-top: -50px;
    opacity: 0; }
  100% {
    margin-top: 0;
    opacity: 1; } }
  .new-order .shipping {
    font-size: 16px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    -webkit-animation: shipping 1s ease-in-out;
            animation: shipping 1s ease-in-out; }
    .new-order .shipping .detail div {
      color: #4694FC;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #c3daf7; }
      .new-order .shipping .detail div span {
        font-size: 16px;
        display: grid;
        color: #505050; }
    .new-order .shipping .payment div {
      color: #4694FC;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 10px;
      padding: 10px;
      border-bottom: 1px solid #c3daf7; }
      .new-order .shipping .payment div span {
        font-size: 16px;
        display: grid;
        color: #505050; }
  .new-order .product-shipping {
    margin-top: 10px; }
    .new-order .product-shipping span {
      color: #505050;
      font-size: 18px;
      font-weight: 500; }
    .new-order .product-shipping .product {
      color: #505050;
      font-size: 16px;
      margin-top: 5px;
      border-radius: 2px;
      background-color: #F7F8F9;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 3px 5px;
      box-shadow: 0 3px 5px lightgray; }

@media (max-width: 700px) {
  .new-order {
    position: relative; }
    .new-order .confirm {
      position: absolute;
      background-color: #4694FC;
      color: white;
      padding: 10px 20px;
      right: 10px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 2px;
      font-weight: 500; }
    .new-order .confirm:hover {
      box-shadow: 0 10px 20px lightgray; }
    .new-order .order {
      background-color: white;
      padding: 10px; }
      .new-order .order span {
        color: #505050;
        font-size: 18px;
        font-weight: 500; }
    .new-order .hide {
      display: grid;
      grid-template-columns: 1fr; }
    .new-order .shipping {
      margin-top: 10px;
      font-size: 16px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 1fr; }
      .new-order .shipping .detail div {
        color: #4694FC;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 10px;
        padding: 10px;
        border-bottom: 1px solid #c3daf7; }
        .new-order .shipping .detail div span {
          font-size: 18px;
          display: grid;
          color: #505050; }
      .new-order .shipping .payment div {
        color: #4694FC;
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 10px;
        padding: 10px;
        border-bottom: 1px solid #c3daf7; }
        .new-order .shipping .payment div span {
          font-size: 18px;
          display: grid;
          color: #505050; }
    .new-order .product-shipping {
      margin-top: 10px; }
      .new-order .product-shipping span {
        color: #505050;
        font-size: 18px;
        font-weight: 500; }
      .new-order .product-shipping .product {
        grid-template-columns: 1fr; } }

.products {
  padding-top: 50px;
  padding-left: 150px; }
  .products .wrapper {
    padding: 50px;
    color: #4694fc; }
    .products .wrapper .new {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      position: fixed;
      top: 70px;
      right: 50px;
      z-index: 5;
      background-color: #4694fc;
      box-shadow: 0px 2px 10px lightblue;
      cursor: pointer;
      -webkit-animation: icon 0.7s ease-in-out;
              animation: icon 0.7s ease-in-out;
      color: white;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5; }
    .products .wrapper .new:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      box-shadow: 0 10px 20px lightgray; }

@-webkit-keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
    .products .wrapper .wrapper-search {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 15px; }
      .products .wrapper .wrapper-search .search {
        margin-top: 8px;
        color: #505050;
        font-weight: 500; }
        .products .wrapper .wrapper-search .search select {
          color: #505050;
          font-weight: 500;
          margin-left: 10px;
          width: 170px;
          font-size: 16px;
          background-color: white;
          border: none;
          border-radius: 30px;
          outline: none;
          cursor: pointer;
          padding: 5px 7px;
          box-shadow: 0px 2px 3px lightgray; }
      .products .wrapper .wrapper-search .search-input {
        margin-top: 8px;
        color: #505050;
        position: relative; }
        .products .wrapper .wrapper-search .search-input input {
          position: absolute;
          border-radius: 30px;
          right: 35px;
          font-size: 16px;
          outline: none;
          width: 90%;
          padding: 5px 13px;
          border: none;
          box-shadow: 0px 2px 3px lightgray; }
        .products .wrapper .wrapper-search .search-input input:focus {
          box-shadow: 0px 5px 10px lightgray; }
        .products .wrapper .wrapper-search .search-input button {
          box-shadow: 0px 2px 3px lightgray;
          border: none;
          border-radius: 50%;
          color: white;
          font-size: 16px;
          background-color: #4694fc;
          position: absolute;
          right: 0px;
          height: 28px;
          width: 28px;
          outline: none;
          cursor: pointer; }
          .products .wrapper .wrapper-search .search-input button i {
            margin: auto; }
        .products .wrapper .wrapper-search .search-input button:hover {
          box-shadow: 0px 5px 10px lightgray; }
    .products .wrapper .show {
      margin-top: 5px;
      width: auto;
      display: flex;
      font-size: 14px; }
      .products .wrapper .show div {
        background-color: white;
        padding: 5px 10px;
        margin-right: 5px;
        border-radius: 5px;
        box-shadow: 0px 2px 3px lightgray;
        cursor: pointer; }
      .products .wrapper .show .active {
        background-color: #4694fc;
        color: white; }
    .products .wrapper .table {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: 10px; }
      .products .wrapper .table .loading-list {
        height: 50px;
        margin-bottom: 10px;
        background-color: white;
        box-shadow: 0 3px 10px lightgray;
        -webkit-animation: loading-list 2s infinite;
                animation: loading-list 2s infinite;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .products .wrapper .table .loading-list div:nth-child(1) {
          height: 15px;
          width: 30%;
          background-color: lightgray; }
        .products .wrapper .table .loading-list div:nth-child(2) {
          height: 15px;
          width: 30%;
          background-color: lightgray;
          margin-top: 15px; }
        .products .wrapper .table .loading-list div:nth-child(3) {
          height: 15px;
          width: 30%;
          background-color: lightgray;
          margin-top: 10px; }

@-webkit-keyframes loading-list {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0.2; } }

@keyframes loading-list {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0.2; } }
      .products .wrapper .table .product {
        padding: 5px 10px;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        background-color: white;
        margin-bottom: 5px;
        border-radius: 2px;
        box-shadow: 0 2px 3px lightgray; }
        .products .wrapper .table .product .photo {
          display: none; }
        .products .wrapper .table .product .photo img {
          background-color: lightgrey;
          height: 40px;
          width: 40px; }
        .products .wrapper .table .product .name {
          color: #505050;
          font-size: 16px;
          font-weight: 450; }
        .products .wrapper .table .product .code div {
          display: none; }
        .products .wrapper .table .product .code span {
          color: #505050;
          font-size: 16px; }
        .products .wrapper .table .product .price div {
          display: none; }
        .products .wrapper .table .product .price span {
          color: #505050;
          font-size: 16px;
          font-weight: 500;
          display: flex;
          flex-direction: column; }
    .products .wrapper .card {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 10px; }
      .products .wrapper .card .loading-list {
        height: 230px;
        background-color: white;
        -webkit-animation: loading-list 2s infinite;
                animation: loading-list 2s infinite;
        box-shadow: 0 3px 10px lightgray;
        padding: 10px; }
        .products .wrapper .card .loading-list div:nth-child(1) {
          height: 170px;
          background-color: lightgray; }
        .products .wrapper .card .loading-list div:nth-child(2) {
          height: 15px;
          background-color: lightgray;
          margin-top: 15px; }
        .products .wrapper .card .loading-list div:nth-child(3) {
          height: 15px;
          width: 50%;
          background-color: lightgray;
          margin-top: 10px; }

@keyframes loading-list {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0.2; } }
      .products .wrapper .card .product {
        display: flex;
        flex-direction: column;
        background: white;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 0 3px 3px lightgray;
        font-size: 14px;
        color: #4694fc; }
        .products .wrapper .card .product .photo {
          min-height: 180x;
          width: 100%; }
        .products .wrapper .card .product .photo img {
          background-color: lightgray;
          min-height: 180px;
          width: 100%; }
        .products .wrapper .card .product .name {
          margin-top: 5px;
          color: #505050;
          font-size: 14px;
          font-weight: 450; }
        .products .wrapper .card .product .code span {
          color: #505050;
          font-size: 14px; }
        .products .wrapper .card .product .price span {
          color: #505050;
          font-size: 16px;
          font-weight: 500; }

@media (max-width: 700px) {
  .products {
    padding-left: 0px;
    padding-bottom: 60px;
    padding-top: 60px; }
    .products .wrapper {
      padding: 10px; }
      .products .wrapper .new {
        top: 68px;
        right: 10px; }
      .products .wrapper .wrapper-search {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 40px; }
      .products .wrapper .table .product {
        display: grid;
        grid-template-columns: 1fr; }
        .products .wrapper .table .product .name {
          color: #505050;
          font-size: 18px;
          font-weight: 450; }
        .products .wrapper .table .product .code {
          color: #4694fc;
          display: flex;
          justify-content: space-between; }
          .products .wrapper .table .product .code div {
            display: block; }
          .products .wrapper .table .product .code span {
            color: #505050;
            font-size: 16px; }
        .products .wrapper .table .product .price {
          color: #4694fc;
          display: flex;
          justify-content: space-between; }
          .products .wrapper .table .product .price div {
            display: block; }
          .products .wrapper .table .product .price span {
            color: #505050;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            flex-direction: column; }
      .products .wrapper .card {
        margin-top: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px; }
        .products .wrapper .card .product .photo {
          min-height: 110px; }
        .products .wrapper .card .product .photo img {
          min-height: 110px; } }

.detail-product {
  padding-top: 60px;
  padding-left: 150px; }
  .detail-product .comfirm-delete {
    position: fixed;
    color: white;
    width: 100%;
    height: 100%;
    z-index: 200;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5); }
    .detail-product .comfirm-delete div {
      opacity: 1;
      margin: 80px auto;
      opacity: 1;
      width: 200px;
      background-color: #4694fc;
      padding: 20px;
      border-radius: 10px;
      text-align: center;
      -webkit-animation: comfirm-delete 1s ease-in-out;
              animation: comfirm-delete 1s ease-in-out; }
      .detail-product .comfirm-delete div h3 {
        margin: 0;
        font-weight: 500; }
      .detail-product .comfirm-delete div button {
        padding: 2px 20px;
        border-radius: 2px;
        border: 1px solid white;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 40px;
        background-color: #4694fc;
        color: white;
        font-size: 15px;
        cursor: pointer; }
      .detail-product .comfirm-delete div button:hover {
        background: white;
        color: #4694fc; }

@-webkit-keyframes comfirm-delete {
  0% {
    margin-top: -100px;
    opacity: 0; }
  50% {
    margin-top: 110px; }
  100% {
    opacity: 1;
    margin-top: 80px; } }

@keyframes comfirm-delete {
  0% {
    margin-top: -100px;
    opacity: 0; }
  50% {
    margin-top: 110px; }
  100% {
    opacity: 1;
    margin-top: 80px; } }
  .detail-product .top {
    background-color: white;
    display: flex;
    top: 0px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 100;
    padding: 0 20px;
    height: 60px;
    width: auto;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 2px lightgray; }
    .detail-product .top .back {
      color: #4694fc; }
    .detail-product .top .action {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px; }
    .detail-product .top .update {
      height: 25px;
      color: white;
      border-radius: 20px;
      background-color: #4DB649;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      height: 40px;
      width: 40px;
      transition: 0.5s;
      box-shadow: 0 5px 10px lightgray; }
    .detail-product .top .delete {
      height: 25px;
      color: white;
      border-radius: 20px;
      background-color: #ff5555;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      cursor: pointer;
      height: 40px;
      width: 40px;
      transition: 0.5s;
      box-shadow: 0 5px 10px lightgray; }
    .detail-product .top .delete:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      box-shadow: 0 10px 20px lightgray; }
    .detail-product .top .update:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      box-shadow: 0 10px 20px lightgray; }
  .detail-product .wrapper {
    padding: 30px 50px 0px 50px;
    color: #4694fc;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    font-weight: 500; }
    .detail-product .wrapper .back {
      color: #4694fc;
      position: absolute;
      top: 100px;
      left: 200px; }
    .detail-product .wrapper .image {
      width: 60%; }
      .detail-product .wrapper .image img {
        width: 30%;
        max-height: 500px; }
      .detail-product .wrapper .image .load {
        background-color: lightgray;
        height: 450px;
        width: 100%;
        -webkit-animation: load 2s ease-in-out infinite;
                animation: load 2s ease-in-out infinite; }

@-webkit-keyframes load {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0.2; } }

@keyframes load {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0.2; } }
    .detail-product .wrapper .detail .box {
      box-shadow: 0px 3px 3px lightgray;
      background-color: white;
      padding: 20px;
      border-radius: 3px; }
    .detail-product .wrapper .detail .message {
      color: red; }
    .detail-product .wrapper .detail .name {
      border-bottom: 1px solid lightgray;
      color: #505050;
      font-size: 22px;
      font-weight: 500;
      padding-bottom: 10px; }
    .detail-product .wrapper .detail .code {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
      margin-top: 5px; }
      .detail-product .wrapper .detail .code span {
        color: #505050;
        font-size: 18px;
        font-weight: 500; }
    .detail-product .wrapper .detail .weight {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      border-bottom: 1px solid lightgray; }
      .detail-product .wrapper .detail .weight span {
        color: #505050;
        font-size: 18px;
        font-weight: 500; }
    .detail-product .wrapper .detail .category div {
      padding: 10px 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightgray;
      margin-top: 5px; }
      .detail-product .wrapper .detail .category div span {
        color: #505050;
        font-size: 18px;
        font-weight: 500; }
    .detail-product .wrapper .detail .size-stock {
      margin-top: 5px;
      display: grid;
      padding: 10px 0;
      grid-template-columns: 1fr 1fr; }
      .detail-product .wrapper .detail .size-stock hr {
        border: none;
        height: 1px;
        background-color: #4694fc;
        opacity: 0.5;
        margin-top: 0; }
      .detail-product .wrapper .detail .size-stock .stock {
        color: #505050;
        font-weight: 500; }
    .detail-product .wrapper .detail .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0px 3px 3px lightgray;
      border-radius: 3px;
      background-color: white;
      padding: 10px 20px;
      margin-top: 10px; }
      .detail-product .wrapper .detail .price span {
        color: #505050;
        font-size: 23px;
        font-weight: 500; }
  .detail-product .description {
    box-shadow: 0px 3px 3px lightgray;
    border-radius: 3px;
    background-color: white;
    margin-top: 5px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 50px;
    padding: 20px;
    color: #4694fc;
    font-weight: 500;
    font-size: 20px; }
    .detail-product .description div {
      color: #505050;
      font-size: 16px;
      font-weight: 400; }
      .detail-product .description div p {
        margin-top: 0px;
        margin-bottom: 0px; }

@media (max-width: 700px) {
  .detail-product {
    padding-top: 60px;
    padding-left: 0px;
    padding-bottom: 10px; }
    .detail-product .top {
      padding: 0 10px; }
    .detail-product .wrapper {
      padding: 10px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px; }
      .detail-product .wrapper .detail .update {
        right: 105px; }
      .detail-product .wrapper .detail .delete {
        right: 10px; }
    .detail-product .description {
      margin: 0px 10px 50px 10px; } }

.categories {
  padding-top: 50px;
  padding-left: 150px; }
  .categories .wrapper {
    padding: 50px;
    color: #4694fc; }
    .categories .wrapper .message {
      color: #ff5555; }
    .categories .wrapper .loading-list {
      background-color: white;
      margin-bottom: 15px;
      height: 50px;
      -webkit-animation: loading-list 2s infinite;
              animation: loading-list 2s infinite; }

@-webkit-keyframes loading-list {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0.2; } }

@keyframes loading-list {
  0% {
    opacity: 0.2; }
  50% {
    opacity: 0.6; }
  100% {
    opacity: 0.2; } }
    .categories .wrapper .new {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      position: absolute;
      top: 70px;
      right: 50px;
      z-index: 5;
      background-color: #4694fc;
      box-shadow: 0px 2px 10px lightgray;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      -webkit-animation: icon 0.7s ease-in-out;
              animation: icon 0.7s ease-in-out;
      transition: 0.5s; }
    .categories .wrapper .new:hover {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      box-shadow: 0 10px 20px lightgray; }

@-webkit-keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
    .categories .wrapper i {
      color: white; }
    .categories .wrapper .comfirm-delete {
      position: fixed;
      color: white;
      width: 100%;
      height: 100%;
      z-index: 300;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5); }
      .categories .wrapper .comfirm-delete div {
        margin: 80px auto;
        opacity: 1;
        width: 200px;
        background-color: #4694fc;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        -webkit-animation: comfirm-delete 1s ease-in-out;
                animation: comfirm-delete 1s ease-in-out; }
        .categories .wrapper .comfirm-delete div h3 {
          margin: 0;
          font-weight: 500; }
        .categories .wrapper .comfirm-delete div button {
          padding: 2px 20px;
          border-radius: 2px;
          border: 1px solid white;
          margin-right: 10px;
          margin-left: 10px;
          margin-top: 40px;
          background-color: #4694fc;
          color: white;
          font-size: 15px;
          cursor: pointer; }
        .categories .wrapper .comfirm-delete div button:hover {
          background: white;
          color: #4694fc; }

@-webkit-keyframes comfirm-delete {
  0% {
    margin-top: -100px;
    opacity: 0; }
  50% {
    margin-top: 110px; }
  100% {
    opacity: 1;
    margin-top: 80px; } }

@keyframes comfirm-delete {
  0% {
    margin-top: -100px;
    opacity: 0; }
  50% {
    margin-top: 110px; }
  100% {
    opacity: 1;
    margin-top: 80px; } }
    .categories .wrapper .category {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #b5d3fa;
      padding: 3px;
      color: #505050;
      align-items: center; }
      .categories .wrapper .category .actions {
        display: flex; }
      .categories .wrapper .category span {
        margin-left: 3px;
        font-size: 20px;
        font-weight: 500; }
      .categories .wrapper .category .add {
        height: 30px;
        width: 30px;
        border-radius: 20px;
        background-color: #4694fc;
        margin-left: 7px;
        box-shadow: 0px 2px 10px lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        cursor: pointer; }
      .categories .wrapper .category .update {
        height: 30px;
        width: 30px;
        border-radius: 20px;
        background-color: #4DB649;
        margin-left: 7px;
        box-shadow: 0px 2px 10px lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        cursor: pointer; }
      .categories .wrapper .category .delete {
        height: 30px;
        width: 30px;
        border-radius: 20px;
        background-color: #ff5555;
        box-shadow: 0px 2px 10px lightgray;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        cursor: pointer; }
    .categories .wrapper .add-subcategory {
      margin-top: 10px;
      background-color: white;
      padding: 5px;
      box-shadow: 0 3px 10px lightgray;
      -webkit-animation: add 1s ease-in-out;
              animation: add 1s ease-in-out;
      border-radius: 3px;
      width: 98%;
      margin-bottom: 10px; }

@-webkit-keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }

@keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }
      .categories .wrapper .add-subcategory form {
        display: flex;
        justify-content: space-between; }
      .categories .wrapper .add-subcategory input {
        font-size: 14px;
        padding: 5px;
        border: 1px solid #87B7FB;
        background-color: #f5f5f5;
        border-radius: 3px;
        width: 92%;
        margin-right: 10px; }
      .categories .wrapper .add-subcategory button {
        background-color: #4694fc;
        border: none;
        color: white;
        border-radius: 3px;
        padding: 0 20px;
        cursor: pointer; }
    .categories .wrapper .list-sub {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 10px; }

@media (max-width: 700px) {
  .categories {
    padding-left: 0px;
    padding-bottom: 60px; }
    .categories .wrapper {
      padding: 30px 10px; }
      .categories .wrapper .new {
        height: 40px;
        width: 40px;
        top: 65px;
        right: 10px; }
      .categories .wrapper .list-sub {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px; }
      .categories .wrapper img {
        width: 100%; }
      .categories .wrapper .add-subcategory form {
        display: flex;
        flex-direction: column; }
        .categories .wrapper .add-subcategory form input {
          margin-bottom: 5px;
          width: auto; }
        .categories .wrapper .add-subcategory form button {
          padding: 5px 0; } }

.add-category {
  margin-top: 10px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 3px 10px lightgray;
  -webkit-animation: add 1s ease-in-out;
          animation: add 1s ease-in-out;
  border-radius: 3px;
  width: 98%;
  margin-bottom: 10px; }

@-webkit-keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }

@keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }
  .add-category form {
    display: flex;
    justify-content: space-between; }
  .add-category input {
    font-size: 14px;
    padding: 5px;
    border: 1px solid #87B7FB;
    background-color: #f5f5f5;
    border-radius: 3px;
    width: 92%; }
  .add-category button {
    background-color: #4694fc;
    border: none;
    color: white;
    border-radius: 3px;
    padding: 0 20px;
    cursor: pointer;
    margin-left: 10px; }

.sub-category {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  color: gray;
  border-radius: 3px;
  box-shadow: 0 3px 3px lightgray; }
  .sub-category .photo {
    min-height: 190px;
    background-color: white;
    margin-bottom: 5px; }
  .sub-category img {
    min-height: 190px;
    height: 100%;
    width: 100%; }
  .sub-category .name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px; }
  .sub-category .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px; }
    .sub-category .actions .update {
      height: 20px;
      color: white;
      padding: 1px 0;
      border-radius: 3px;
      background-color: #4DB649;
      box-shadow: 0px 2px 10px lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      cursor: pointer; }
    .sub-category .actions .update:hover {
      box-shadow: 0px 10px 20px lightgray; }
    .sub-category .actions .delete {
      height: 20px;
      color: white;
      padding: 1px 5px;
      border-radius: 3px;
      background-color: #ff5555;
      box-shadow: 0px 2px 10px lightgray;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      cursor: pointer; }
    .sub-category .actions .delete:hover {
      box-shadow: 0px 10px 20px lightgray; }

.update-subcategory {
  margin-top: 10px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 3px 10px lightgray;
  -webkit-animation: add 1s ease-in-out;
          animation: add 1s ease-in-out;
  border-radius: 3px;
  width: 99%;
  margin-bottom: 10px; }

@-webkit-keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }

@keyframes add {
  0% {
    opacity: 0;
    width: 0%; }
  50% {
    width: 105%; }
  100% {
    width: 99%;
    opacity: 1; } }
  .update-subcategory form {
    display: flex;
    justify-content: space-between; }
  .update-subcategory input {
    font-size: 14px;
    padding: 5px;
    border: 1px solid #87B7FB;
    background-color: #f5f5f5;
    border-radius: 3px;
    width: 93%;
    margin-right: 5px;
    margin-top: 5px;
    transition: 1s; }
  .update-subcategory button {
    margin-top: 5px;
    background-color: #4694fc;
    border: none;
    color: white;
    border-radius: 3px;
    padding: 0 10px;
    cursor: pointer; }

@media (max-width: 700px) {
  .sub-category .actions {
    display: grid;
    grid-template-columns: 1fr; }
  .sub-category .photo {
    height: auto;
    min-height: 140px; }
  .sub-category img {
    min-height: 140px;
    height: auto; } }

.add-wrapper {
  padding-top: 60px;
  padding-left: 150px; }
  .add-wrapper .cancel {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    position: fixed;
    top: 70px;
    right: 50px;
    z-index: 5;
    background-color: #4694fc;
    box-shadow: 0px 2px 10px lightgray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    -webkit-animation: icon 0.7s ease-in-out;
            animation: icon 0.7s ease-in-out;
    transition: 0.5s;
    color: white; }
  .add-wrapper .cancel:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    box-shadow: 0 10px 20px lightgray; }

@-webkit-keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
  .add-wrapper .add-product {
    background-color: white;
    color: gray;
    margin: 50px;
    padding: 20px 50px 50px 50px;
    box-shadow: 0px 2px 10px lightgray;
    display: flex;
    flex-direction: column;
    border-radius: 5px; }
    .add-wrapper .add-product h1 {
      color: #4694fc;
      margin-top: 0px;
      text-align: center;
      font-weight: 500; }
    .add-wrapper .add-product .success {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0; }
      .add-wrapper .add-product .success div {
        margin: 150px auto;
        height: 80px;
        width: 250px;
        background-color: #4694fc;
        color: white;
        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-animation: success 1s ease-in-out;
                animation: success 1s ease-in-out; }

@-webkit-keyframes success {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes success {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
    .add-wrapper .add-product .photo .image {
      display: flex;
      justify-content: center; }
      .add-wrapper .add-product .photo .image img {
        width: 60%;
        height: 60%; }
    .add-wrapper .add-product .photo label {
      margin-bottom: 2px;
      font-weight: 520;
      color: #4694fc;
      font-size: 17px; }
    .add-wrapper .add-product .photo input {
      margin-bottom: 15px; }
    .add-wrapper .add-product form {
      display: flex;
      flex-direction: column; }
      .add-wrapper .add-product form label {
        margin-bottom: 2px;
        font-weight: 520;
        color: #4694fc; }
      .add-wrapper .add-product form input {
        margin-bottom: 15px;
        border: 1px solid lightblue;
        padding: 5px 8px;
        font-size: 16px; }
      .add-wrapper .add-product form select {
        margin-bottom: 10px;
        font-size: 16px;
        padding: 2px 5px;
        border: 1px solid lightblue;
        background-color: white; }
      .add-wrapper .add-product form .size-stock {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .add-wrapper .add-product form .size-stock input {
          width: 50%;
          margin-bottom: 2px; }
        .add-wrapper .add-product form .size-stock hr {
          margin-top: 1px;
          border: none;
          height: 2px;
          background-color: #4694fc;
          border-radius: 2px;
          opacity: 0.5; }
        .add-wrapper .add-product form .size-stock .size {
          font-size: 20px;
          color: black;
          font-weight: 500; }
        .add-wrapper .add-product form .size-stock .stock {
          font-size: 20px;
          color: #4694fc;
          font-weight: 500;
          position: relative; }
          .add-wrapper .add-product form .size-stock .stock span {
            top: -6px;
            padding: 3px 0;
            border-radius: 50%;
            position: absolute;
            right: 0;
            color: white;
            background-color: #ED515E;
            cursor: pointer; }
      .add-wrapper .add-product form .description {
        height: 300px;
        margin-bottom: 100px; }
      .add-wrapper .add-product form .message {
        color: red;
        margin: 0 auto;
        margin-bottom: 5px; }
      .add-wrapper .add-product form .add {
        border: 1px solid #4694fc;
        background: #4694fc;
        text-align: center;
        color: white;
        padding: 3px 0;
        margin-bottom: 15px;
        border-radius: 20px;
        cursor: pointer; }
      .add-wrapper .add-product form .add:hover {
        background-color: white;
        color: #4694fc; }
      .add-wrapper .add-product form button {
        border: 1px solid #4694fc;
        background-color: #4694fc;
        color: white;
        font-size: 20px;
        font-weight: 520;
        cursor: pointer;
        padding: 5px 2px;
        box-shadow: 0px 2px 10px lightgray;
        transition: 0.5s; }
      .add-wrapper .add-product form button:hover {
        background-color: white;
        color: #4694fc; }

@media (max-width: 700px) {
  .add-wrapper {
    padding-left: 0px;
    padding-bottom: 50px; }
    .add-wrapper .cancel {
      right: 10px; }
    .add-wrapper .add-product {
      margin: 15px;
      padding: 15px; }
      .add-wrapper .add-product .photo .image img {
        width: 100%; } }

.update-product {
  padding-top: 60px;
  padding-left: 150px; }
  .update-product .cancel {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    position: fixed;
    top: 70px;
    right: 50px;
    z-index: 5;
    background-color: #4694fc;
    box-shadow: 0px 2px 10px lightgray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    -webkit-animation: icon 0.7s ease-in-out;
            animation: icon 0.7s ease-in-out;
    transition: 0.5s;
    color: white; }
  .update-product .cancel:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    box-shadow: 0 10px 20px lightgray; }

@-webkit-keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes icon {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
  .update-product .add-product {
    background-color: white;
    color: gray;
    margin: 50px;
    padding: 20px 50px 50px 50px;
    box-shadow: 0px 2px 10px lightgray;
    display: flex;
    flex-direction: column;
    border-radius: 5px; }
    .update-product .add-product h1 {
      color: #4694fc;
      margin-top: 0px;
      text-align: center;
      font-weight: 500; }
    .update-product .add-product .success {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0; }
      .update-product .add-product .success div {
        margin: 150px auto;
        height: 80px;
        width: 250px;
        background-color: #4694fc;
        color: white;
        font-size: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        -webkit-animation: success 1s ease-in-out;
                animation: success 1s ease-in-out; }

@-webkit-keyframes success {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes success {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2); }
  50% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }
    .update-product .add-product .photo .image {
      display: flex;
      justify-content: center; }
      .update-product .add-product .photo .image img {
        width: 30%;
        height: 30%; }
    .update-product .add-product .photo label {
      margin-bottom: 2px;
      font-weight: 520;
      color: #4694fc;
      font-size: 17px; }
    .update-product .add-product .photo input {
      margin-bottom: 15px; }
    .update-product .add-product form {
      display: flex;
      flex-direction: column; }
      .update-product .add-product form label {
        margin-bottom: 2px;
        font-weight: 520;
        color: #4694fc; }
      .update-product .add-product form input {
        margin-bottom: 15px;
        border: 1px solid lightblue;
        padding: 5px 8px;
        font-size: 16px; }
      .update-product .add-product form select {
        margin-bottom: 10px;
        font-size: 16px;
        padding: 2px 5px;
        border: 1px solid lightblue;
        background-color: white; }
      .update-product .add-product form .size-stock {
        display: grid;
        grid-template-columns: 1fr 1fr; }
        .update-product .add-product form .size-stock input {
          border-radius: 5px;
          width: 50%;
          margin-bottom: 2px; }
        .update-product .add-product form .size-stock hr {
          margin-top: 1px;
          border: none;
          height: 2px;
          background-color: #4694fc;
          border-radius: 2px;
          opacity: 0.5; }
        .update-product .add-product form .size-stock .size {
          font-size: 20px;
          color: black;
          font-weight: 500; }
        .update-product .add-product form .size-stock .stock {
          font-size: 20px;
          color: #4694fc;
          font-weight: 500; }
        .update-product .add-product form .size-stock .stock span {
          padding: 3px 0;
          border-radius: 50%;
          margin-left: 10px;
          color: white;
          background-color: #ED515E;
          cursor: pointer; }
      .update-product .add-product form .description {
        height: 300px;
        margin-bottom: 100px; }
      .update-product .add-product form .message {
        color: red;
        margin: 0 auto;
        margin-bottom: 5px; }
      .update-product .add-product form .add {
        border: 1px solid #4694fc;
        background: #4694fc;
        text-align: center;
        color: white;
        padding: 3px 0;
        margin-bottom: 15px;
        border-radius: 20px;
        cursor: pointer; }
      .update-product .add-product form .add:hover {
        background-color: white;
        color: #4694fc; }
      .update-product .add-product form button {
        border: 1px solid #4694fc;
        background-color: #4694fc;
        color: white;
        font-size: 20px;
        font-weight: 520;
        cursor: pointer;
        padding: 5px 0;
        box-shadow: 0px 8px 20px lightgray; }
      .update-product .add-product form button:hover {
        background-color: white;
        color: #4694fc; }

@media (max-width: 700px) {
  .update-product {
    padding-left: 0px;
    padding-bottom: 50px; }
    .update-product .cancel {
      right: 10px; }
    .update-product .add-product {
      margin: 15px;
      padding: 20px; }
      .update-product .add-product .photo .image img {
        width: 100%; } }

.customers {
  padding-top: 50px;
  padding-left: 150px; }
  .customers .wrapper {
    padding: 50px;
    color: #4694fc; }
    .customers .wrapper .loading-customers {
      height: 40px;
      width: 100%;
      border-radius: 30px;
      background-color: white;
      margin-top: 10px;
      -webkit-animation: loading-customers 1.5s ease-in-out infinite;
              animation: loading-customers 1.5s ease-in-out infinite; }

@-webkit-keyframes loading-customers {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.4; } }

@keyframes loading-customers {
  0% {
    opacity: 0.4; }
  50% {
    opacity: 0.9; }
  100% {
    opacity: 0.4; } }
    .customers .wrapper .customer {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      background-color: white;
      padding: 5px;
      color: #505050;
      border-radius: 30px;
      align-items: center;
      box-shadow: 0 3px 2px lightgray;
      -webkit-animation: customer 1s ease-in-out;
              animation: customer 1s ease-in-out; }
      .customers .wrapper .customer .photo {
        display: flex;
        align-items: center; }
        .customers .wrapper .customer .photo img {
          background-color: lightgray;
          height: 30px;
          width: 30px;
          margin-right: 10px;
          border-radius: 50%; }
        .customers .wrapper .customer .photo span {
          font-weight: 500; }

@-webkit-keyframes customer {
  0% {
    margin-top: -50px;
    opacity: 0; }
  100% {
    margin-top: 10px;
    opacity: 1; } }

@keyframes customer {
  0% {
    margin-top: -50px;
    opacity: 0; }
  100% {
    margin-top: 10px;
    opacity: 1; } }

@media (max-width: 700px) {
  .customers {
    padding-left: 0px;
    padding-top: 60px;
    padding-bottom: 60px; }
    .customers .wrapper {
      padding: 10px; }
      .customers .wrapper .customer {
        padding: 10px;
        border-radius: 20px;
        display: grid;
        grid-template-columns: 1fr; }
        .customers .wrapper .customer .photo {
          margin-bottom: 5px; } }

.account {
  padding-top: 50px;
  padding-left: 150px; }
  .account .wrapper {
    padding: 50px;
    color: #505050;
    font-size: 18px;
    -webkit-animation: account 1s ease-in-out;
            animation: account 1s ease-in-out; }
    .account .wrapper .change {
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 202;
      background-color: rgba(0, 0, 0, 0.5); }
      .account .wrapper .change form {
        background-color: white;
        width: 300px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        margin: 100px auto;
        -webkit-animation: change 0.5s ease-in-out;
                animation: change 0.5s ease-in-out; }
        .account .wrapper .change form h2 {
          margin-top: 0;
          font-weight: 500; }
        .account .wrapper .change form label {
          font-size: 16px; }
        .account .wrapper .change form input {
          margin-bottom: 15px;
          font-size: 18px;
          padding: 5px 7px;
          border: 1px solid #4bcffa;
          border-radius: 3px; }
        .account .wrapper .change form button {
          font-size: 18px;
          cursor: pointer;
          padding: 3px;
          background-image: linear-gradient(#5daafd, #4bcffa);
          border: none;
          color: white;
          border-radius: 3px;
          box-shadow: 0px 3px 20px lightgrey; }
        .account .wrapper .change form .edit {
          top: 5px;
          font-size: 35px;
          right: 5px;
          color: #5D8EFB;
          cursor: pointer;
          position: absolute; }

@-webkit-keyframes change {
  0% {
    margin-top: -300px; }
  100% {
    margin-top: 100px; } }

@keyframes change {
  0% {
    margin-top: -300px; }
  100% {
    margin-top: 100px; } }
    .account .wrapper .profile {
      border-radius: 10px;
      box-shadow: 0px 3px 3px lightgray;
      background-color: white;
      height: auto;
      max-width: 350px;
      margin: 10px auto;
      padding: 20px;
      padding-bottom: 50px;
      position: relative; }
      .account .wrapper .profile .edit {
        top: 15px;
        font-size: 35px;
        right: 15px;
        color: #5D8EFB;
        cursor: pointer;
        position: absolute; }
      .account .wrapper .profile input {
        padding: 2px 5px;
        border: none;
        border-bottom: 1px solid lightblue;
        font-size: 18px;
        outline: none;
        width: 100%; }
      .account .wrapper .profile button {
        border: none;
        color: white;
        border-radius: 20px;
        outline: none;
        cursor: pointer;
        font-size: 18px;
        background-image: linear-gradient(#5daafd, #4bcffa);
        padding: 3px 0; }
      .account .wrapper .profile button:hover {
        box-shadow: 0 5px 10px lightgray; }
      .account .wrapper .profile .photo {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin: 10px auto;
        background-color: lightgray; }
        .account .wrapper .profile .photo img {
          height: 100%;
          width: 100%;
          border-radius: 50%; }
      .account .wrapper .profile .username {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: 10px;
        border-bottom: 1px solid lightgray; }
      .account .wrapper .profile .name {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: 10px;
        border-bottom: 1px solid lightgray; }
      .account .wrapper .profile .email {
        display: grid;
        grid-template-columns: 1fr 2fr;
        padding: 10px;
        border-bottom: 1px solid lightgray; }

@-webkit-keyframes account {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1; } }

@keyframes account {
  0% {
    opacity: 0;
    -webkit-transform: scaleY(0);
            transform: scaleY(0); }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1; } }

@media (max-width: 700px) {
  .account {
    padding-top: 60px;
    padding-left: 0px;
    padding-bottom: 60px; }
    .account .wrapper {
      padding: 10px; }
      .account .wrapper .profile {
        width: 85%; } }

