.new-order {
    position: relative;

    .load {
        div {
            width: 100%;
            height: 50px;
            background-color: lightgray;
            margin-bottom: 10px;
            animation: load 2s infinite;
        }

        @keyframes laod {
            0%{
                opacity: 0.2;
            }
            50%{
                opacity: 0.9;
            }
            100%{
                opacity: 0.2;
            }
        }
        
    }

    .confirm {
        position: absolute;
        background-color: #4694FC;
        color: white;
        padding: 10px 20px;
        right: 10px;
        font-size: 16px;
        cursor: pointer;
        border-radius: 2px;
        font-weight: 500;
    }

    .confirm:hover {
        box-shadow: 0 10px 20px lightgray;
    }

    .confirm-process {
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 250;
        
        div {
            background-color: white;
            width: 300px;
            padding: 20px;
            margin: 100px auto;
            border-radius: 10px;

            label {
                font-size: 18px;
                font-weight: 500;
            }

            input {
                border: 1px solid #4694FC;
                border-radius: 3px;
                width: 95%;
                margin-top: 5px;
                margin-bottom: 10px;
                font-size: 18px;
                padding: 3px 5px;
            }
            
            button {
                margin-left: 35px;
                background-color: #4694FC;
                border: none;
                font-size: 16px;
                padding: 5px 20px;
                border-radius: 25px;
                color: white;
                cursor: pointer;
            }

            button:hover {
                box-shadow: 0 10px 10px lightgrey;
            }
        }
    }

    .order {
        margin-bottom: 10px;
        border-radius: 5px;
        cursor: pointer;
        background-color: white;
        padding: 10px;
        box-shadow: 0 3px 5px lightgray;
        animation: order 1s ease-in-out;

        span {
            color: #505050;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .hide {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        span {
            font-size: 16px;
        }
    }

    @keyframes order {
        0%{
            margin-top: -50px;
            opacity: 0;
        }
        100%{
            margin-top: 0;
            opacity: 1;
        }
    }

    .shipping {
        font-size: 16px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        animation: shipping 1s ease-in-out;

        .detail div {
            color: #4694FC;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 10px;
            padding: 10px;
            border-bottom: 1px solid rgb(195, 218, 247);

            span {
                font-size: 16px;
                display: grid;
                color: #505050;
            }
        }

        .payment div {
            color: #4694FC;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 10px;
            padding: 10px;
            border-bottom: 1px solid rgb(195, 218, 247);

            span {
                font-size: 16px;
                display: grid;
                color: #505050;
            }
        }


    }

    .product-shipping {
        margin-top: 10px;
        span {
            color: #505050;
            font-size: 18px;
            font-weight: 500;
        }
        .product {
            color: #505050;
            font-size: 16px;
            margin-top: 5px;
            border-radius: 2px;
            background-color: #F7F8F9;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 3px 5px;
            box-shadow: 0 3px 5px lightgray;

        }
    }
}

@media (max-width: 700px){
    .new-order {
        position: relative;
        .confirm {
            position: absolute;
            background-color: #4694FC;
            color: white;
            padding: 10px 20px;
            right: 10px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 2px;
            font-weight: 500;
        }
    
        .confirm:hover {
            box-shadow: 0 10px 20px lightgray;
        }
    
        .order {
            background-color: white;
            padding: 10px;
    
            span {
                color: #505050;
                font-size: 18px;
                font-weight: 500;
            }
        }

        .hide {
            display: grid;
            grid-template-columns: 1fr;
        }
    
        .shipping {
            margin-top: 10px;
            font-size: 16px;
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr;
    
            .detail div {
                color: #4694FC;

                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-gap: 10px;
                padding: 10px;
                border-bottom: 1px solid rgb(195, 218, 247);
    
                span {
                    font-size: 18px;
                    display: grid;
                    color: #505050;
                }
            }
    
            .payment div {
                color: #4694FC;

                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-gap: 10px;
                padding: 10px;
                border-bottom: 1px solid rgb(195, 218, 247);
    
                span {
                    font-size: 18px;
                    display: grid;
                    color: #505050;
                }
            }
        }
    
        .product-shipping {
            margin-top: 10px;
            span {
                color: #505050;
                font-size: 18px;
                font-weight: 500;
            }
            .product {
                grid-template-columns: 1fr;
            }
        }
    }
}