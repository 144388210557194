.login {

    .wrapper {
        background-color: white;
        width: 310px;
        height: 385px;
        border-radius: 15px;
        box-shadow: 0px 2px 0px lightgray;
        position: absolute;
        right: 15%;
        top: 110px;
        z-index: 50;
        animation: form 2s ease-in-out;

        img {
            width: 100%;
            position: absolute;
        }

        .error {
            color: #c83737;
            position: absolute;
            top: 13px;
        }

        form {
            margin-top: -116px;
            margin-left: 25px;
            margin-right: 25px;
            position: relative;
        }

        @keyframes form {
            0%{
                top: 0;
                opacity: 0;
            }
            100%{
                top:110px;
                opacity: 1;
            }
        }

        h2 {
            margin-top: 230px;
            color: #5daafd;
            font-weight: 400;
            margin-bottom: 20px;
        }

        label {
            color: #5daafd;
            position: absolute;
        }

        input {
            width: 91%;
            padding: 5px 10px;
            margin-top: 7px;
            margin-bottom: 10px;
            border: 1px solid rgb(190, 215, 252);
            font-size: 16px;
            background-color: #eaf4ff;
            border-radius: 3px;
            outline: none;
            box-shadow: 0 3px 3px lightgray;
            transition: 0.5s;
            position: absolute;
        }
        input:focus {
            transform: scale(1.1);
            background-color: white;
        }

        #username {
            top: 70px;
        }


        #password {
            top: 140px;
        }


        button {
            width: 260px;
            font-size: 16px;
            background-image: linear-gradient(#5daafd, #4bcffa);
            border: none;
            color: white;
            padding: 7px;
            border-radius: 3px;
            box-shadow: 0px 3px 20px lightgrey;
            transition: 0.5s;
            position: absolute;
            top: 200px;
            animation: button 1.5s ease-in;
        }

        @keyframes button {
            0%{
                top: 0;
                opacity: 0;
            }
            50%{
                top: 220px 
            }
            100%{
                opacity: 1;
                top: 200px;
            }
        }


        button:hover {
            
            transform: scale(1.1);
            cursor: pointer;
        }
    }

    .left {
        .background {
            position: fixed;
            width: auto;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1;
        }

        .person {
            z-index: 5;
            position: fixed;
            height: 40%;
            top: 45%;
            left: 15%;
        }

        .screen1 {
            top: 15%;
            left: 23%;
            z-index: 2;
            height: 30%;
            position: fixed;
            animation: screen1 2s ease-in-out infinite;
        }

        @keyframes screen1 {
            0%{
                top: 15%;
            }
            50%{
                top: 10%;
            }
            100% {
                top: 15%;
            }
        }

        .screen2 {
            top: 20%;
            left: 13%;
            z-index: 2;
            height: 15%;
            position: fixed;
            animation: screen2 1.5s ease-in-out infinite;
        }

        @keyframes screen2 {
            0%{
                top: 25%;
            }
            50%{
                top: 30%;
            }
            100% {
                top: 25%;
            }
        }

        .screen3 {
            top: 40%;
            left: 25%;
            z-index: 2;
            height: 25%;
            position: fixed;
            animation: screen3 2s ease-in-out infinite;
        }

        @keyframes screen3 {
            0%{
                top: 43%;
            }
            50%{
                top: 48%;
            }
            100% {
                top: 43%;
            }
        }

        .icons {
            position: fixed;
            z-index: 2;
            height:70%;
            top: 8%;
            left: 6%;
            animation: icons 2s ease-in-out;
        }

        @keyframes icons {
            0%{
                top:-50%;
                opacity: 0;
            }
            75%{
                top:15%;
            }
            100%{
                top: 8%;
                opacity: 1;
            }
        }

    }

}

// responsive mobile
@media (max-width: 700px) {
    .login {
        display: flex;
        justify-content: center;

        .wrapper {
            position: relative;
            left: auto;
            right: auto;
        }
    }


}