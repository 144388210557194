.detail-product {
    padding-top: 60px;
    padding-left: 150px;

    .comfirm-delete {
        position: fixed;
        color: white;
        width: 100%;
        height: 100%;
        z-index: 200;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);    

        div {
            opacity: 1;
            margin: 80px auto;
            opacity: 1;
            width: 200px;
            background-color: #4694fc;
            padding: 20px;
            border-radius: 10px;
            text-align: center;
            animation: comfirm-delete 1s ease-in-out;

            h3 {
                margin: 0;
                font-weight: 500;
            }

            button {
                padding: 2px 20px;
                border-radius: 2px;
                border: 1px solid white;
                margin-right: 10px;
                margin-left: 10px;
                margin-top: 40px;
                background-color: #4694fc;
                color: white;
                font-size: 15px;
                cursor: pointer;
            }

            button:hover {
                background: white;
                color: #4694fc;
            }
            
        }

        @keyframes comfirm-delete {
            0%{
                margin-top: -100px;
                opacity: 0;
            }
            50%{
                margin-top: 110px;
            }
            100%{
                opacity: 1;
                margin-top: 80px;
            }
        }
    }

    .top {
        background-color: white;
        display: flex;
        top: 0px;
        position: sticky;
        z-index: 100;
        padding: 0 20px;
        height: 60px;
        width: auto;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 2px lightgray;

        .back {
            color: #4694fc;
        }
        

        .action {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 8px;
        }

        .update {
            height: 25px;
            color: white;
            border-radius: 20px;
            background-color: #4DB649;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
            height: 40px;
            width: 40px;
            transition: 0.5s;
            box-shadow: 0 5px 10px lightgray;
        }

        .delete {
            height: 25px;
            color: white;
            border-radius: 20px;
            background-color: #ff5555;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
            height: 40px;
            width: 40px;
            transition: 0.5s;
            box-shadow: 0 5px 10px lightgray;
        }

        .delete:hover {
            transform: scale(1.2);
            box-shadow: 0 10px 20px lightgray;
        }

        .update:hover {
            transform: scale(1.2);
            box-shadow: 0 10px 20px lightgray;
        }

    }


    .wrapper {
        padding: 30px 50px 0px 50px;
        color: #4694fc;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
        font-weight: 500;


        .back {
            color: #4694fc;
            position: absolute;
            top: 100px;
            left: 200px;
        }

        .image {
            width: 60%;
            img {
                width: 30%;
                max-height: 500px;
            }

            .load {
                background-color: lightgray;
                height: 450px;
                width: 100%;
                animation: load 2s ease-in-out infinite;
            }

            @keyframes load {
                0%{
                    opacity: 0.2;
                }
                50%{
                    opacity: 0.8;
                }
                100%{
                    opacity: 0.2;
                }
            }


        }

        .detail {
            
            .box {
                box-shadow: 0px 3px 3px lightgray;
                background-color: white;
                padding: 20px;
                border-radius: 3px;
            }
            
            .message {
                color: red;
            }


            .name {
                border-bottom: 1px solid lightgray;
                color: #505050;
                font-size: 22px;
                font-weight: 500;
                padding-bottom: 10px;
            }

            .code {
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid lightgray;
                margin-top: 5px;
                span {
                    color: #505050;
                    font-size: 18px;
                    font-weight: 500;
                }
            }


            .weight {
                padding: 10px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 5px;
                border-bottom: 1px solid lightgray;
                span {
                    color: #505050;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .category  div{
                padding: 10px 0;
                align-items: center;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid lightgray;
                margin-top: 5px;
                span {
                    color: #505050;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            
            .size-stock {
                margin-top: 5px;
                display: grid;
                padding: 10px 0;
                grid-template-columns: 1fr 1fr;

                hr {
                    border: none;
                    height: 1px;
                    background-color: #4694fc;
                    opacity: 0.5;
                    margin-top: 0;
                }

                .stock {
                    color: #505050;
                    font-weight: 500;
                }

            }


            .price {
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 3px 3px lightgray;
                border-radius: 3px;
                background-color: white;
                padding: 10px 20px;
                margin-top: 10px;
                span {
                    color: #505050;
                    font-size: 23px;
                    font-weight: 500;
                }
            }
        }
    }


    .description {
        box-shadow: 0px 3px 3px lightgray;
        border-radius: 3px;
        background-color: white;            
        margin-top: 5px;
        margin-left: 50px;
        margin-right: 50px;
        margin-bottom: 50px;
        padding: 20px;
        color: #4694fc;
        font-weight: 500;
        font-size: 20px;

        div {
            color: #505050;
            font-size: 16px;
            font-weight: 400;

            p {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

@media (max-width: 700px){
    .detail-product {
        padding-top: 60px;
        padding-left: 0px;
        padding-bottom: 10px;

        .top {
            padding: 0 10px;
        }

        .wrapper {
            padding: 10px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;


            .detail {
                .update {
                    right: 105px;
                }

                .delete {
                    right: 10px;
                }
            }
        }

        .description {
            margin: 0px 10px 50px 10px;
        }
    }
}