.account {
    padding-top: 50px;
    padding-left: 150px;

    .wrapper {
        padding: 50px;
        color: #505050;
        font-size: 18px;
        animation: account 1s ease-in-out;

        .change {
            position: fixed;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 202;
            background-color: rgba($color: #000000, $alpha: 0.5);

            form {
                background-color: white;
                width: 300px;
                display: flex;
                flex-direction: column;
                padding: 20px;
                border-radius: 10px;
                position: relative;
                margin: 100px auto;
                animation: change 0.5s ease-in-out;

                h2 {
                    margin-top: 0;
                    font-weight: 500;
                }

                label {
                    font-size: 16px;
                }

                input {
                    margin-bottom: 15px;
                    font-size: 18px;
                    padding: 5px 7px;
                    border: 1px solid #4bcffa;
                    border-radius: 3px;
                }

                button {
                    font-size: 18px;
                    cursor: pointer;
                    padding: 3px;
                    background-image: linear-gradient(#5daafd, #4bcffa);
                    border: none;
                    color: white;
                    border-radius: 3px;
                    box-shadow: 0px 3px 20px lightgrey;
                }

                .edit {
                    top: 5px;
                    font-size: 35px;
                    right: 5px;
                    color: #5D8EFB;
                    cursor: pointer;
                    position: absolute;            
                }
            }

            @keyframes change {
                0%{
                    margin-top: -300px;
                }
                100%{
                    margin-top: 100px;
                }
            }
        }

        .profile {
            border-radius: 10px;
            box-shadow: 0px 3px 3px lightgray;
            background-color: white;
            height: auto;
            max-width: 350px;
            margin: 10px auto;
            padding: 20px;
            padding-bottom: 50px;
            position: relative;

            .edit {
                top: 15px;
                font-size: 35px;
                right: 15px;
                color: #5D8EFB;
                cursor: pointer;
                position: absolute;            
            }

            input {
                padding: 2px 5px;
                border: none;
                border-bottom: 1px solid lightblue;
                font-size: 18px;
                outline: none;
                width: 100%;
            }

            button {
                border: none;
                color: white;
                border-radius: 20px;
                outline: none;
                cursor: pointer;
                font-size: 18px;
                background-image: linear-gradient(#5daafd, #4bcffa);                
                padding: 3px 0;
            }

            button:hover {
                box-shadow: 0 5px 10px lightgray;
            }

            .photo {
                width: 200px;
                height: 200px;
                border-radius: 50%;
                margin: 10px auto;
                background-color: lightgray;

                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }
            }

            .username {
                display: grid;
                grid-template-columns: 1fr 2fr;
                padding: 10px;
                border-bottom: 1px solid lightgray;
            }

            .name {
                display: grid;
                grid-template-columns: 1fr 2fr;
                padding: 10px;
                border-bottom: 1px solid lightgray;
            }

            .email {
                display: grid;
                grid-template-columns: 1fr 2fr;
                padding: 10px;
                border-bottom: 1px solid lightgray;
            }
        }

    }

    @keyframes account {
        0%{
            opacity: 0;
            transform: scaleY(0);
        }
        100%{
            transform: scaleY(1);
            opacity: 1;
        }
    }
}

@media (max-width: 700px){
    .account {
        padding-top: 60px;
        padding-left: 0px;
        padding-bottom: 60px;

        .wrapper {
            padding: 10px;
            
            .profile {
                width: 85%;
            }
        }
    }
}