.header {
    top: 0;
    position: fixed;
    width: 100%;
    background-color: #4694fc;
    color: white;
    height: 60px;
    display: flex;
    z-index: 10;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 5px lightgray;
    justify-content: space-between;
    align-items: center;

    .title {
        margin-left: 12px;
        font-size: 22px;
        font-weight: 500;
    }

    .admin {
        margin-right: 18px;

        span {
            cursor: pointer;
        }

        .dropdown {
            width: 150px;
            height: auto;
            position: absolute;
            background-color: white;
            color: gray;
            padding: 15px;
            margin-top: 10px;
            right: 25px;
            border-radius: 20px;
            box-shadow: 0px 3px 3px lightgray;
            display: flex;
            flex-direction: column;
            animation: admin 1s ease-in-out;
            text-align: center;

            img {
                width: 130px;
                height: 130px;
                border-radius: 50%;
                margin-left: 11px;
                background-color: lightgray;
            }

            .name {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 500;
                color: #4694fc;
            }

            .email {
                margin-top: 5px;
                font-size: 13px;
            }

            .logout {
                margin-top: 15px;
                text-align: center;
                background-color: #F6535A;
                padding: 5px 5px;
                color: white;
                border-radius: 20px;
                box-shadow: 0px 5px 10px lightgray;
                transition: 0.5s;
            }

            .logout:hover {
                box-shadow: 0px 10px 10px lightgray;                
            }

            @keyframes admin {
                0%{
                    top: -500px;
                }
                50%{
                    top: 80px
                }
                100%{
                    top: 40px;
                }
            }

        }

        .hide {
            display: none;
        }
    }
    
}